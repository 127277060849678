import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

class Cases extends React.Component {
  render() {
    const cases = this.props.allSanityCaseStudy.edges

    return (
      <>
        <section id="case-studies-container">
          <div id="case-studies" className="bg-indigo py-5">
            <div id="item-featured-container" className="">
              <div className="container">
                <div className="row">
                  {!cases
                    ? null
                    : cases.map((studies, idx) => {
                        const StudyBannerImg = getImage(
                          studies.node.bannerImage
                            ? studies.node.bannerImage.asset.gatsbyImageData
                            : ""
                        )
                        const StudyBannerBgImg = convertToBgImage(
                          StudyBannerImg
                        )

                        const StudyMainImg = getImage(
                          studies.node.mainImage
                            ? studies.node.mainImage.asset.gatsbyImageData
                            : ""
                        )
                        const StudyMainBgImg = convertToBgImage(StudyMainImg)

                        return (
                          <div key={idx} className="col-md-4 mb-4 mb-md-0">
                            <BackgroundImage
                              Tag="div"
                              id="hero-case-studies"
                              className="item-featured"
                              // Spread bgImage into BackgroundImage:
                              {...StudyBannerBgImg}
                              preserveStackingContext
                              style={{
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              <Link
                                to={`/case-study/${studies.node.slug.current}/`}
                              >
                                <div className="item-lead">
                                  <div className="nth-color">
                                    <div className="item-overlay-color" />
                                  </div>
                                  <BackgroundImage
                                    Tag="div"
                                    id="hero-case-studies"
                                    className="item-featured"
                                    // Spread bgImage into BackgroundImage:
                                    {...StudyMainBgImg}
                                    preserveStackingContext
                                    style={{
                                      borderRadius: "5px",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      overflow: "hidden",
                                    }}
                                  />
                                </div>
                                <div className="item-content">
                                  <h4
                                    className="text-white mt-0"
                                    style={{ zIndex: 5 }}
                                  >
                                    {studies.node.title}
                                  </h4>
                                </div>
                              </Link>
                            </BackgroundImage>
                          </div>
                        )
                      })}
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link
                className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                to="/case-studies/"
              >
                More Case Studies
              </Link>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Cases
